import { Link } from 'gatsby'
import React from 'react'

import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'

const TermsAndConditionsPage = ({ location }) => (
  <Layout location={location}>
    <PageContent>
      <Header />
      <div className='plain-text'>
        <SEO title='Terms of Use' />
        <div className='heading'>Terms of Use</div>
        <div className='divider' />
        <p>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
          SITE
        </p>

        <h3>What&apos;s in these terms?</h3>
        <p>
          These terms tell you the rules for using our website TheBiz.pro (
          <strong>“Site”</strong>). By using our Site, you confirm that you
          accept these terms and that you agree to comply with them.
        </p>
        <p>
          The way we handle personal information on the Site is described in our{' '}
          <Link to='/privacy'>Privacy Statement</Link>. Further, the terms
          should be read in conjunction with our{' '}
          <Link to='/cookies'>Cookies Policy</Link>.
        </p>
        <p>
          Click on the links below to go straight to more information on each
          area:
        </p>
        <ul className='links'>
          <li>
            • <Link to='#who-we-are'>Who we are and how to contact us</Link>
          </li>
          <li>
            • <Link to='#what-we-do'>What we do</Link>
          </li>
          <li>
            • <Link to='#funding-the-biz'>Funding The Biz.</Link>
          </li>
          <li>
            •{' '}
            <Link to='#accept-terms'>
              By using our Site you accept these terms
            </Link>
          </li>
          <li>
            • <Link to='#change-terms'>We may make changes to these terms</Link>
          </li>
          <li>
            • <Link to='#change-site'>We may make changes to our Site.</Link>
          </li>
          <li>
            •{' '}
            <Link to='#suspend-site'>We may suspend or withdraw our Site.</Link>
          </li>
          <li>
            {' '}
            •{' '}
            <Link to='#transfer-agreement'>
              We may transfer this agreement to someone else.
            </Link>
          </li>
          <li>
            •{' '}
            <Link to='#site-not-for-certain-users'>
              Our Site is not for certain users.
            </Link>
          </li>
          <li>
            •{' '}
            <Link to='#how-use-material'>
              How you may use material on our Site.
            </Link>
          </li>
          <li>
            •{' '}
            <Link to='#dont-rely'>Do not rely on information on our Site.</Link>
          </li>
          <li>
            • <Link to='#biz-content'>Content of The Biz.</Link>
          </li>
          <li>
            •{' '}
            <Link to='#not-responsible'>
              We are not responsible for websites we link to.
            </Link>
          </li>
          <li>
            •{' '}
            <Link to='#our-responsibility'>
              Our responsibility for loss or damage suffered by you.
            </Link>
          </li>
          <li>
            •{' '}
            <Link to='#how-use-personal-info'>
              How we may use your personal information.
            </Link>
          </li>
          <li>
            •{' '}
            <Link to='#not-responsible-for-viruses'>
              We are not responsible for viruses and you must not introduce
              them.
            </Link>
          </li>
          <li>
            • <Link to='#indemnity'>Indemnity.</Link>
          </li>
          <li>
            • <Link to='#rules'>Rules about linking to our Site.</Link>
          </li>
          <li>
            •{' '}
            <Link to='#laws'>
              Which country&apos;s laws apply to any disputes?
            </Link>
          </li>
          <li>
            • <Link to='#general'>General.</Link>
          </li>
        </ul>

        <h4 id='who-we-are'>Who we are and how to contact us</h4>
        <p>
          TheBiz.pro is a website operated by Synonym Software Ltd. (
          <strong>&quot;We&quot;</strong>).{' '}
        </p>

        <p>
          We are registered in the British Virgin Islands under company number
          2056692 and have our registered office at Synonym Services Ltd.,
          Trinity Chambers, PO Box 4301, Road Town, Tortola VG1110, British
          Virgin Islands To contact us, please email{' '}
          <a href='mailto:privacy@synonym.to'>privacy@synonym.to</a>.
        </p>
        <p>We are a limited company.</p>
        <p>
          Please note that we do not offer bitcoin, any cryptocurrency or
          regulated investments through our Site. We are therefore not
          authorised or regulated by any financial authority in any
          jurisdiction. Consequently you will have no recourse to any financial
          services compensation schemes in any jurisdiction.
        </p>

        <h4 id='what-we-do'>What we do</h4>
        <p>We run a crowdfunded podcast named “The Biz.”</p>
        <p>
          Following the release of its first episode, The Biz. will use
          crowdfunding to fund further episode releases. You will be able via
          the Site to contribute funds towards unlocking such further episode
          content (<strong>“Contributions”</strong>).
        </p>
        <p>
          You should note Contributions are not linked to any acquisition of
          equity or other financial interest in Synonym Software Ltd.
        </p>
        <p>
          A funding target for each episode will be published at the Site (
          <strong>“Target”</strong>) and when any new Contribution is received,
          a proportional amount of content to the goal for that episode will be
          progressively unlocked on the Site.
        </p>
        <p>
          Once content for a particular episode is unlocked by the Contributions
          reaching the Target, all visitors to the Site will gain free access to
          that episode. When an episode is 100% unlocked it may be distributed
          as We wish.
        </p>
        <p>
          A portion of the Contributions raised for any episode may be utilized
          for a list of projects, curated by Synonym Software Ltd. and chosen by
          the podcast guest. The projects associated with any podcast episode
          may be identified in the description of the podcast, in whole or part,
          but may not be disclosed or known at the time of Contribution.
        </p>

        <h4 id='funding-the-biz'>Funding The Biz.</h4>
        <p>
          QR codes will be publicly available on the Site for payment of
          Contributions towards funding further episodes of The Biz.
        </p>
        <p>
          You agree that a certain minimum Contribution amount may apply, and
          that all Contributions are final and will not be refunded unless We in
          our sole discretion, agree to issue a refund.
        </p>
        <p>
          Payment of Contributions may be made from any Bitcoin or Lightning
          Network wallet. Blockchain transactions such as Contribution payments
          are irreversible and We have no ability to reverse, alter, or
          otherwise change any transactions.
        </p>

        <h4 id='accept-terms'>By using our Site you accept these terms</h4>
        <p>
          By using our Site, you confirm that you accept these terms of use and
          that you agree to comply with them.
        </p>
        <p>If you do not agree to these terms, you must not use our Site.</p>
        <p>
          We recommend that you print a copy of these terms for future
          reference.
        </p>

        <h4 id='change-terms'>We may make changes to these terms</h4>
        <p>
          We amend these terms from time to time. Every time you wish to use our
          Site, please check these terms to ensure you understand the terms that
          apply at that time. When we do this, we will post the revised terms on
          this page and will indicate the date of such amendments.
        </p>

        <h4 id='change-site'>We may make changes to our Site</h4>
        <p>
          We may update and change our Site from time to time. We will try to
          give you reasonable notice of any major changes.
        </p>

        <h4 id='suspend-site'>We may suspend or withdraw our Site</h4>
        <p>
          We do not guarantee that our Site, or any content on it, will always
          be available or be uninterrupted. We may suspend or withdraw or
          restrict the availability of all or any part of our Site for business
          and operational reasons. We will try to give you reasonable notice of
          any suspension or withdrawal.
        </p>
        <p>
          You are also responsible for ensuring that all persons who access our
          Site through your internet connection are aware of these terms of use
          and other applicable terms and conditions, and that they comply with
          them.
        </p>

        <h4 id='transfer-agreement'>
          We may transfer this agreement to someone else
        </h4>
        <p>
          We may transfer our rights and obligations under these terms to
          another organisation. We will always tell you in writing if this
          happens and we will ensure that the transfer will not affect your
          rights under the contract.
        </p>

        <h4 id='site-not-for-certain-users'>
          Our Site is not for certain users{' '}
        </h4>
        <p>
          Our Site is not directed to U.S. citizens, people residing or present
          in the United States or any person identified on, or present or
          residing in a country included on, any sanctions lists identified by
          the Office of Foreign Assets Control of the U.S. Department of the
          Treasury, the Financial Investigation Authority of the British Virgin
          Islands, or the United Nations.
        </p>
        <p>
          You must be at least 18 years of age or, if higher, the age at which
          you are legally recognised as an adult in your jurisdiction to use our
          Site.
        </p>

        <h4 id='how-use-material'>How you may use material on our Site</h4>
        <p>
          We are the owner or the licensee of all intellectual property rights
          in our Site, and in the material published on it. Those works are
          protected by copyright laws and treaties around the world. All such
          rights are reserved.
        </p>
        <p>
          You may print off one copy, and may download extracts, of any page(s)
          from our Site for your personal use and you may draw the attention of
          others within your organisation to content posted on our Site. You
          must not modify the paper or digital copies of any materials you have
          printed off or downloaded in any way, and you must not use any
          illustrations, photographs, video or audio sequences or any graphics
          separately from any accompanying text.
        </p>
        <p>
          Our status (and that of any identified contributors) as the authors of
          content on our Site must always be acknowledged.
        </p>
        <p>
          You must not use any part of the content on our Site for commercial
          purposes without obtaining a licence to do so from us or our
          licensors.
        </p>
        <p>
          If you print off, copy or download any part of our Site in breach of
          these terms of use, your right to use our Site will cease immediately
          and you must, at our option, return or destroy any copies of the
          materials you have made.
        </p>
        <h4 id='dont-rely'>Do not rely on information on this Site</h4>
        <p>
          The content on our Site is provided for general information only. It
          is not intended to amount to a recommendation or advice on which you
          should rely. You must obtain professional or specialist advice before
          taking, or refraining from, any action on the basis of the content on
          our Site.
        </p>
        <p>
          Although we make reasonable efforts to update the information on our
          Site, we make no representations, warranties or guarantees, whether
          express or implied, that the content on our Site is accurate, complete
          or up to date.
        </p>

        <h4 id='biz-content'>Content of The Biz</h4>
        <p>The content of each episode of The Biz.:</p>
        <ul>
          <li>
            • has been prepared for informational purposes only without regard
            to any particular user’s investment objectives, financial situation,
            or means, and We are not soliciting any action based upon it;
          </li>
          <li>
            • is not to be construed as a recommendation; or an offer to buy or
            sell; or the solicitation of an offer to buy or sell bitcoin, any
            cryptocurrency, security, financial product, or instrument; or to
            participate in any particular financial investment;
          </li>
        </ul>
        <p>
          The views and opinions expressed in each episode of The Biz. are those
          of the hosts, guests and other contributors and do not reflect the
          views of Synonym Software Ltd. or any of its affiliates.
        </p>
        <h4 id='not-responsible'>
          We are not responsible for websites we link to
        </h4>
        <p>
          Where our Site contains links to other sites and resources provided by
          third parties or any of our affiliates these links are provided for
          your information only. Such links should not be interpreted as
          approval by us of those linked websites or information you may obtain
          from them.
        </p>
        <p>
          We have no control over the contents of those sites or resources, or
          their privacy settings, policies and/or procedures. It is your sole
          responsibility to review the information provided by those sites or
          resources.
        </p>

        <h4 id='our-responsibility'>
          Our responsibility for loss or damage suffered by you
        </h4>
        <ul>
          <li>
            • We do not exclude or limit in any way our liability to you where
            it would be unlawful to do so. This includes liability for death or
            personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors and for fraud or fraudulent
            misrepresentation.
          </li>
          <li>
            • We exclude all implied conditions, warranties, representations or
            other terms that may apply to our Site or any content on it.
          </li>
          <li>
            • To the extent permitted by applicable law, We will not be liable
            to you for any loss or damage, whether in contract, tort (including
            negligence), breach of statutory duty, or otherwise, even if
            foreseeable, arising under or in connection with:
            <ul className='sublists'>
              <li>• use of, or inability to use, our Site; or</li>
              <li>
                • use of or reliance on any content displayed on our Site.
              </li>
              <li>• In particular, we will not be liable for:</li>
              <li>• loss of profits, sales, business, or revenue;</li>
              <li>• business interruption;</li>
              <li>• loss of anticipated savings;</li>
              <li>
                • loss of business opportunity, goodwill or reputation; or
              </li>
              <li>• any indirect or consequential loss or damage.</li>
            </ul>
          </li>
        </ul>
        <h4 id='how-use-personal-info'>
          How we may use your personal information
        </h4>
        <p>
          For more information on how we handle personal information, please
          refer to our
          <Link to='/privacy'>Privacy Statement</Link>.
        </p>
        <h4 id='not-responsible-for-viruses'>
          We are not responsible for viruses and you must not introduce them
        </h4>
        <p>
          We do not guarantee that our Site will be secure or free from bugs or
          viruses.
        </p>
        <p>
          You are responsible for configuring your information technology,
          computer programmes and platform to access our Site. You should use
          your own virus protection software.
        </p>
        <p>
          You must not misuse our Site by knowingly introducing viruses,
          trojans, worms, logic bombs or other material that is malicious or
          technologically harmful. You must not attempt to gain unauthorised
          access to our Site, the server on which our Site is stored or any
          server, computer or database connected to our Site. You must not
          attack our Site via a denial-of-service attack or a distributed
          denial-of service attack. By breaching this provision, you would
          commit a criminal offence under the Computer Misuse Act 1990. We will
          report any such breach to the relevant law enforcement authorities and
          we will co-operate with those authorities by disclosing your identity
          to them. In the event of such a breach, your right to use our Site
          will cease immediately.
        </p>

        <h4 id='indemnity'>Indemnity</h4>
        <p>
          You agree only to use the Site in accordance with these terms. You
          agree that you will compensate Synonym Software Ltd. (and our
          employees, officers, agents, affiliates and suppliers) in full for any
          damages, losses, costs and expenses, including reasonable legal fees
          we incur that arise out of any breach by you of these terms or any
          liability we incur as a result of the use of our Site by you.
        </p>

        <h4 id='rules'>Rules about linking to our Site</h4>
        <p>
          You may link to our home page, provided you do so in a way that is
          fair and legal and does not damage our reputation or take advantage of
          it.
        </p>
        <p>
          You must not establish a link in such a way as to suggest any form of
          association, approval or endorsement on our part where none exists.
        </p>
        <p>
          You must not establish a link to our Site in any website that is not
          owned by you.
        </p>
        <p>
          Our Site must not be framed on any other site, nor may you create a
          link to any part of our Site other than the home page.
        </p>
        <p>
          We reserve the right to withdraw linking permission without notice.
        </p>
        <p>
          If you wish to link to or make any use of content on our Site other
          than that set out above, please contact us through the email address
          provided above.
        </p>
        <h4 id='laws'>Which country&apos;s laws apply to any disputes?</h4>
        <p>
          Please note that these terms of use, their subject matter and their
          formation, are governed by English law. We both agree that the courts
          of England and Wales will have exclusive jurisdiction. The foregoing
          shall be without prejudice to any applicable provisions of mandatory
          consumer protection laws under the laws of your country of residence,
          to the extent that these offer you more protection in the European
          Union.
        </p>

        <h4 id='general'>General</h4>
        <p>
          Entire agreement: these terms of use constitute the entire agreement
          between you and Synonym Software Ltd. and supersede any prior
          agreements between you and Synonym Software Ltd. You may also be
          subject to additional terms of service that may apply when you use
          affiliate or third-party services, third-party content or third-party
          software.
        </p>
        <p>
          Severability: if any provision of these terms of use is found by a
          court of competent jurisdiction to be (or are otherwise) invalid, the
          parties nevertheless agree that the court should endeavour to give
          effect to the parties&apos; intentions as reflected in the provision,
          and the other provisions of these terms shall remain in full force and
          effect.
        </p>
        <p>
          Waiver: if you breach these terms and we choose to ignore your breach,
          we shall still be entitled to use our rights and remedies at a later
          date or in any other circumstances where you breach these terms again.
        </p>
      </div>
      <FooterMenu className='page-content__footer' />
    </PageContent>
  </Layout>
)

export default TermsAndConditionsPage
